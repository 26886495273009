<template>
  <div class="countDown" :class="blinkClass">
    <div class="title" v-if="remain > 0">{{ title }}</div>
    <div class="timer" v-if="remain > 0">
      <div class="item">
        <div class="value">{{ format(days) }}</div>
        <div class="unit">{{ $t('day') }}</div>
      </div>
      <div class="item">
        <div class="value">{{ format(hours) }}</div>
        <div class="unit">{{ $t('hour') }}</div>
      </div>
      <div class="item">
        <div class="value">{{ format(minutes) }}</div>
        <div class="unit">{{ $t('minute') }}</div>
      </div>
      <div class="item">
        <div class="value">{{ format(seconds) }}</div>
        <div class="unit">{{ $t('second') }}</div>
      </div>
    </div>
    <div class="finish" v-if="remain <= 0">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    title: String,
    endTimeMs: Number,
    blinkTime: Number,
    tick: Function,
    finish: Function,
  },
  components: {},
  data() {
    return {
      blinkClass: '',
      remain: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    }
  },
  mounted() {
    this.start()
  },
  methods: {
    start() {
      clearInterval(this.intervalId || 0)
      this.countdown();
      this.intervalId = setInterval(() => {
        this.countdown()
      }, 1000)
    },
    format(n) {
      if (n > 9) {
        return n;
      }
      return '0' + n;
    },
    countdown() {
      const remain = ((this.$props.endTimeMs - Date.now()) / 1000) | 0
      this.remain = remain
      this.days = (remain / 24 / 3600) | 0
      this.hours = ((remain % (3600 * 24)) / 3600) | 0
      this.minutes = ((remain % 3600) / 60) | 0
      this.seconds = remain % 60

      this.$emit('tick', remain)
      if (remain < 0) {
        clearInterval(this.intervalId)
        this.$emit('finish')
      }
    },
    blink() {
      this.blinkClass = 'blink'
      setTimeout(() => {
        this.blinkClass = ''
      }, 1200)
    }
  },
  watch: {
    endTimeMs(v) {
      this.start()
    },
    blinkTime(v) {
      this.blink()
    }
  },
  destroyed() {
    clearInterval(this.intervalId)
  }
}
</script>

<style scoped lang="less">
@font-face {
  font-family: 'DS-Digital';
  src:  url(~@/assets/ds_digital/DS-DIGIT-4.ttf);
}

.countDown {
  &.blink {
    box-shadow: 0 0 10px 5px red;
    transition: box-shadow 0.2s;
  }

  display: block;
  padding: 1em;
  background: #101010;
  font-size: 1.2em;
  transition: box-shadow 0.5s;
  color:white;
  max-width: 90vw;

  .title {
    font-size: 32px;
    text-align: center;
    padding: 0.5em 0;
    opacity: 0.85;
  }

  .timer {
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;
    justify-content: space-evenly;
    gap: 16px;

    .item {
      display: inline-flex;
      flex-wrap: nowrap;
      align-items: baseline;

      .value {
        color: #991FF9;
        text-align: center;
        font-family: DS-Digital;
        font-size: 128px;
        font-style: italic;
        font-weight: 700;
        line-height: normal;
        opacity: 0.9;
      }

      .unit {
        font-size: 20px;
        opacity: 0.65;
      }
    }
  }

  .finish {
    text-align:center .link {
      margin-bottom: 0.5em;
      border: 1px solid #882be8;
      color: #11e8f7;
      text-align: center;
      padding: 0.5em 1em;
    }
  }
}
</style>
