<!--首页-->
<template>
<div class="bgLayer" style="background:#111;" ><!---->
    <home-nav></home-nav>
    <home-content></home-content>
    <home-footer></home-footer>
</div>
</template>

<script>
import HomeFooter from './components/homeFooter.vue';
import HomeContent from './components/homeContent';
import HomeNav from './components/homeNav.vue';
export default {
    components: {
       HomeFooter,HomeNav,HomeContent
    },
     data () {
        return {
        };
    },
    mounted () {
       
    },
    methods: {
        //本页初始化
    },
};
</script>
<style lang="less" scoped>
</style>