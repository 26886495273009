export const newsList = [
  {
    title: 'MEKE conducts Airdrops as Prelude to opBNB Mainnet launch',
    desc: 'The widespread adoption of blockchain technology has long been hindered by the sluggish processing speeds and exorbitant GAS fees associated with underlying public chains. Surprisingly, the emergence of Layer 2 (L2) solutions has effectively addressed this challenge. ',
    url: 'https://cointelegraph.com/press-releases/meke-conducts-airdrops-as-prelude-to-opbnb-mainnet-launch',
    date: 'Published: Sep 27, 2023',
    source: 'Source: Cointelegraph.com',
    thumb: 'https://s3.cointelegraph.com/storage/uploads/view/c469861639c877d385b3fee78956a6e3.jpg'
  },
  {
    title: "Seize the MEKE Beta Opportunity & Capture the Essence of BNB Chain's Odyssey",
    desc: "By engaging in MEKE's public beta, participants complete tasks required by the opBNB Odyssey event and increase their chances of earning rewards.",
    url: 'https://www.bsc.news/post/seize-the-meke-beta-opportunity-capture-the-essence-of-bnb-chains-odyssey',
    date: 'Published: Aug 12, 2023',
    source: 'Source: BSC News',
    thumb: 'https://global-uploads.webflow.com/5fad86e2327507cecea2d5e8/64d79aa1e1bb1551aeedb8d4_MEKE-p-1080.jpg'
  },
  {
    title: 'MEKE prepares Binance Chain for the L2 market against Coinbase',
    desc: 'On July 30, a meme coin called BALD was deployed on the coinbase L2 network BASE and opened for trading at LeetSwap. Overnight alone, the meme coin, which had no applications, reached a thousand-fold increase, prompting BASETVL to triple overnight to over 60 million US dollars.',
    url: 'https://cointelegraph.com/press-releases/meke-prepares-binance-chain-for-the-l2-market-against-coinbase',
    date: 'Published: Aug 08, 2023',
    source: 'Source: Cointelegraph.com',
    thumb:
      'https://images.cointelegraph.com/images/638_aHR0cHM6Ly9zMy5jb2ludGVsZWdyYXBoLmNvbS9zdG9yYWdlL3VwbG9hZHMvdmlldy82OGM0ZGZjMzE4MzNmZWIwOGQxOTFjMjk4NTcwNDhlNi5qcGc=.jpg'
  },
  {
    title: 'MEKE’s popular public beta boosts BNB L2 trading volume',
    desc: 'Over the past year, the most prominent trend in the cryptocurrency market has been the emergence of layer-2 networks. Layer 2, also known as L2, refers to the second layer of solutions designed to address the issues of scalability, speed and high gas fees that are present in L1 networks such as Ethereum and BNB Chain. This introduction of L2 solutions has presented the cryptocurrency market with numerous possibilities.',
    url: 'https://cointelegraph.com/press-releases/mekes-popular-public-beta-boosts-bnb-l2-trading-volume',
    date: 'Published: Jul 31, 2023',
    source: 'Source: Cointelegraph.com',
    thumb:
      'https://images.cointelegraph.com/images/587_aHR0cHM6Ly9zMy5jb2ludGVsZWdyYXBoLmNvbS9zdG9yYWdlL3VwbG9hZHMvdmlldy9hYWE0MjBmNzY1Yzc3ZGQyNDNlMmRhYWZiZmE0Zjg2Yy5qcGc=.jpg'
  },
  {
    title:
      'MEKE, the Pioneering Derivative Platform on the OpBNB Blockchain, is Set to Launch its Public Beta on July 31',
    desc: "Providing a seamless derivatives trading experience, users are invited to participate in MEKE's public beta testing, with the platform offering generous rewards.",
    url: 'https://www.bsc.news/post/meke-the-pioneering-derivative-platform-on-the-opbnb-blockchain-is-set-to-launch-its-public-beta-on-july-31',
    date: 'Published: Jul 28, 2023',
    source: 'Source: BSC News',
    thumb: 'https://global-uploads.webflow.com/5fad86e2327507cecea2d5e8/64c37fd9631009fa57785305_MEKE-p-1080.jpeg'
  },
  {
    title: 'The first derivatives trading platform on the OpBNB chain',
    desc: 'The public beta will start on July 31.',
    url: 'https://twitter.com/MEKE_PROTOCOL/status/1683824031412125697',
    date: 'Published: Jul 25, 2023',
    source: 'Twitter: @MEKE_PROTOCOL',
    thumb: 'https://pbs.twimg.com/media/F14k6j_WAAAMSD3?format=webp&name=small'
  },
  {
    title: 'The countdown to the first phase of the MEKE public beta has started',
    desc: 'The countdown to the first phase of the MEKE public beta has started, and the public beta will start at 7:00 (UTC) on July 31.',
    url: 'https://twitter.com/MEKE_PROTOCOL/status/1683392389967142912',
    date: 'Published: Jul 24, 2023',
    source: 'Twitter: @MEKE_PROTOCOL',
    thumb: 'https://pbs.twimg.com/media/F1ycXM6WwAIAk-O?format=webp&name=small'
  },
  {
    title: 'Top Crypto Derivatives Trading Platform MEKE: Public Beta Countdown',
    desc: 'Offering a secure and transparent trading experience on opBNB, early MEKE users will receive the platform token "MEKE" airdrops, as well as fee dividends and reductions.',
    url: 'https://www.bsc.news/post/top-crypto-derivatives-trading-platform-meke-public-beta-countdown',
    date: 'Published: Jul 19, 2023',
    source: 'Source: BSC News',
    thumb: 'https://global-uploads.webflow.com/5fad86e2327507cecea2d5e8/64b81362b7f7c9ddd1a5112f_MEKEPR-p-1080.jpg'
  }
]
