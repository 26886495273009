<!--加载中弹窗-->
<template>
<div>
    <Modal v-model="isShow" width="400px" height="400px" :closable="false" :mask-closable="false" class-name="vertical-center-modal">
        <template slot="header">
            <span></span>
        </template>
        <div class="flexCenter" style="height:200px;flex-direction: column;">
            <!-- <img class="demo-spin-icon-load" :src="require('@/assets/img/loading.svg')" style="width:50px;height:50px;" /> -->
            <div class="pswp__preloader__icn">
                <div class="pswp__preloader__cut">
                <div class="pswp__preloader__donut"></div>
                </div>
            </div>
            <div v-if="text" style="margin-top:30px;font-size:20px;">{{text}}</div>
        </div>
        <template slot="footer">
            <span></span>
        </template>
    </Modal>
</div>
</template>

<script>
export default {
    name: '',
    model:{
        prop:'isShow',
        event:'change'
    },
    props: {
        //连接钱包弹窗，是否显示
        isShow:{
            type:Boolean,
            default:false
        },
        //文本提示
        text:{
            type:String,
            default:''
        }
    },
    components: {

    },
    data () {
        return {
        }  
    },
    mounted() {

    },
    methods: {
    },
}
</script>

<style lang='less'>
/**此处不能添加scoped，否则iview的垂直居中不生效 */
.vertical-center-modal{
    display: flex;
    align-items: center;
    justify-content: center;

    .ivu-modal{
        top: 0;
    }
}
.demo-spin-icon-load{
    animation: ani-demo-spin 2s linear infinite;
}
@keyframes ani-demo-spin {
    from { transform: rotate(0deg);}
    50%  { transform: rotate(180deg);}
    to   { transform: rotate(360deg);}
}
.demo-spin-col{
    height: 100px;
    position: relative;
    border: 1px solid #eee;
}
/**css加载中 */
.pswp__preloader__icn {
    opacity:0.75;
    width: 50px;
    height: 50px;
    -webkit-animation: clockwise 1000ms linear infinite;
    animation: clockwise 1000ms linear infinite;
}
.pswp__preloader__cut {
    position: relative;
    width: 25px;
    height: 50px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.pswp__preloader__donut {
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    border: 3px solid #6179fa;
    border-radius: 50%;
    border-left-color: transparent;
    border-bottom-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    background: none;
    margin:0;
    -webkit-animation: donut-rotate 2000ms cubic-bezier(.4,0,.22,1) infinite;
    animation: donut-rotate 2000ms cubic-bezier(.4,0,.22,1) infinite;
}

@-webkit-keyframes clockwise {
    0% { -webkit-transform: rotate(0deg) }
    100% { -webkit-transform: rotate(360deg) }
}
@keyframes clockwise {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
}
@-webkit-keyframes donut-rotate {
    0% { -webkit-transform: rotate(0) }
    50% { -webkit-transform: rotate(-140deg) }
    100% { -webkit-transform: rotate(0) }
}
@keyframes donut-rotate {
    0% { transform: rotate(0) }
    50% { transform: rotate(-140deg) }
    100% { transform: rotate(0) }
}
</style>